.catering-slider {
	padding-bottom: 50px;
	&__slide {
		position: relative;
		height: auto;

		&:nth-of-type(n+2) {
			display: none;
		}

		.slick-initialized & {
			display: block;
		}
	}

	&__img-wrap{
		&:before{
			padding-bottom: 330px;

			@include media-breakpoint-up(md) {
				padding-bottom: 500px;
			}

			@include media-breakpoint-up(lg) {
				padding-bottom: cp(467,1167);
			}
		}

		&:after{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(#000, 0.4);
		}
	}

	&__slide-title{
		font-family: $font-2;
		text-transform: uppercase;
		text-align: center;
		font-size: 30px;
		color: $color-brown;
		margin-top: 30px;

		@include media-breakpoint-up(md) {
			font-size: 55px;
		}

		@include media-breakpoint-up(lg) {
			position: absolute;
			z-index: 1;
			top: 50%;
			left: 0;
			right: 0;
			transform: translateY(-50%);
			color: #fff;

			width: 75%;
			margin: auto;
		}
	}

	.slick{
		&-slider{
			height: auto;
		}
		&-track,
		&-list{
			height: auto;
		}

		&-arrow {
			&.slick-next ,
			&.slick-prev {
				@include media-breakpoint-down(md) {
					top: 200px;
					margin-top: 0;
				}

				@include media-breakpoint-down(sm) {
					top: 110px;
				}
			}
		}

		&-dots{
			position: absolute;
			bottom: auto;
			top: 100%;
			left: -3px;
			right: -3px;
			padding: 20px 10px;
			width: auto;
			@include media-breakpoint-up(md) {
				text-align: center;
			}
			@include media-breakpoint-up(lg) {
				padding: 20px 0;
				text-align: right;
			}
		}
	}
}

.catering-descr {
	width: 100%;
	max-width: 605px;
	margin: 0 auto;
	line-height: 1.35;
	margin-bottom: 35px;
	font-size: 18px;
}

.catering-title {
	font-size: 30px;
	font-family: $font-2;
	font-weight: $fw-regular;
	color: $color-brown;
	@include media-breakpoint-up(md) {
		font-size: 40px;
	}
}

.b-presentation {
	display: flex;
	flex-wrap: wrap;

	&__img-big{
		@include make-col-ready(0);

		@include media-breakpoint-up(md) {
			@include col-grow();
		}

		&:before{
			padding-bottom: 500px;
			@include media-breakpoint-up(md) {
				padding-bottom: cp(9,14)
			}
		}
	}

	&__content{
		@include make-col-ready(0);
		background-color: #B89E62;
		color: #fff;
		padding: 0 25px 40px;

		@include media-breakpoint-up(md) {
			@include col-auto();
			padding: 40px 47px;
			width: 385px;
			order: 1;
		}

		@include media-breakpoint-up(lg) {
			padding: 40px 57px;
			width: 440px;
		}

		@include media-breakpoint-up(xl) {
			@include make-col(1,3);
			min-width: 440px;
		}
	}

	&__img-small{
		max-width: 300px;
		margin: -15px auto 35px;
		@include media-breakpoint-up(md) {
			margin-top: -70px;
			max-width: none;
		}
	}

	&__title{
		font-size: 14px;
		text-transform: uppercase;
		margin-bottom: 20px;
	}

	&__text{
		font-size: 13px;
		font-weight: $fw-light;
		line-height: em(24, 13);
	}
}

.b-advantages {
	&__list{
		@include make-row(50);
		list-style: none;
		padding: 0;
		margin-top: -50px;
	}
	&__item{
		@include make-col-ready(50);
		margin-top: 50px;

		@include media-breakpoint-up(md) {
			@include make-col(1,2);
		}

		@include media-breakpoint-up(lg) {
			@include make-col(1,3);
		}
	}
}

.item-advantages {
	&__img-wrap{
		margin-bottom: 25px;
		height: 54px;
		display: flex;
		align-items: flex-end;

		img {
			max-height: 100%;
		}
	}
	&__title{
		margin-bottom: 23px;
		font-size: 14px;
		text-transform: uppercase;
	}
	&__text{
		line-height: em(24, 13);
		font-size: 13px;
		font-weight: $fw-light;
	}
}

.menu-banner {
	position: relative;
	z-index: 1;
	height: 570px;
	display: flex;
	align-items: center;
	@include media-breakpoint-up(md) {
		padding: 84px 0;
		height: auto;
	}
	&__img-wrap{
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		&:before{
			display: none;
		}

		&:after{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(#000, 0.47);
		}
	}
	&__title{
		color: $color-brown;
		font-size: 24px;
		margin-bottom: 30px;
	}

	&__btn{
		width: 237px;
	}
}

.b-projects {

	&--bg-dimmed{
		background-color: #F0F3F1;
	}

	.menu-banner ~ &{
		@include media-breakpoint-up(lg) {
			padding-top: 220px;
			margin-top: -135px;
		}
	}

	&__title{
		margin-bottom: 25px;
	}
	&__descr{
		max-width: 450px;
		margin: 0 auto;
	}
	&__list{
		display: flex;
		flex-wrap: wrap;

		list-style: none;
		padding: 0;
		margin-top: -32px;

		@include media-breakpoint-up(md) {
			margin-left: -20px;
			margin-right: -20px;
		}

		@include media-breakpoint-up(lg) {
			margin-left: -35px;
			margin-right: -35px;
			margin-top: -50px;
		}
	}
	&__item{
		@include make-col-ready(0);
		margin-top: 32px;

		@include media-breakpoint-up(md) {
			@include make-col(1,2);
			padding-left: 20px;
			padding-right: 20px;
		}

		@include media-breakpoint-up(lg) {
			padding-left: 35px;
			padding-right: 35px;
			margin-top: 50px;
		}
	}

	&__btn-wrap{
		margin-top: 40px;
	}
	&__btn{
		width: 237px;
	}
}

.item-projects {
	display: block;
	text-decoration: none;
	&:hover{
		text-decoration: none;
	}

	&__img-wrap{
		margin-left: -25px;
		margin-right: -25px;
		@include media-breakpoint-up(md) {
			margin: 0;
		}
		&:before{
			padding-bottom: cp(300, 450);
		}
	}
	&__overlay{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($color-green, 0.75);
		border-bottom: 0 solid $color-brown;

		opacity: 0;
		transition: all 0.2s;

		.item-projects:hover &{
			opacity: 1;
			border-bottom-width: 9px;
		}
	}
	&__icon{
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: em(-38, 76);
		margin-left: em(-38, 76);

		font-size: 52px;
		width: 1em;
		height: 1em;

		border-radius: 50%;
		border: 2px solid $color-brown;
		opacity: 0;
		transition: all 0.2s;
		transform: scale(0.8);

		@include media-breakpoint-up(lg) {
			font-size: 76px;
		}

		.item-projects:hover &{
			opacity: 1;
			transform: scale(1);
		}

		&:before,
		&:after{
			content: '';
			position: absolute;
			top: 0; bottom: 0; left: 0; right: 0;
			margin: auto;
			background-color: $color-brown;
		}
		&:before{
			width: em(28, 76);
			height: 2px;
		}
		&:after{
			width: 2px;
			height: em(28, 76);
		}
	}
	&__title{
		margin-top: 10px;
		font-size: 24px;
		color: $color-brown;
		.item-projects:hover &{
			text-decoration: underline;
		}
	}
}

.b-clients {
	&__title{
		margin-bottom: 28px;
	}
	&__text{
		max-width: 450px;
		margin: 0 auto;
	}
	&__slider{
		height: auto;
		.slick{
			&-list{
				height: auto;
			}
			&-track{
				display: flex;
				align-items: center;
				height: auto;
				&:before,
				&:after{
					display: none;
				}
			}
			&-dots{
				margin-top: 20px;
			}
		}
	}
	&__slide{
		text-align: center;
		height: auto;
	}
	&__img-wrap{
		display: flex;
		justify-content: center;
		align-items: center;
		img {
			max-width: 100%;
			max-height: 110px;
		}
	}
}
