.button {
	@extend %link-clicker;
	display: inline-flex;
	align-items: center;
	justify-content: center;

	height: 41px;
	min-width: 183px;
	padding: 0 33px;

	border: 2px solid rgba(#fff, 0);
	border-radius: 26px;
	text-align: center;
	background: $color-dimmed;

	user-select: none;
	font-family: $font-1;
	font-weight: $fw-bold;
	color: $color-text;
	cursor: pointer;
	text-decoration: none;
	font-size: 14px;
	text-transform: uppercase;

	box-shadow: none;
	transition: background 0.15s, color 0.15s, border 0.15s;
	&:hover{
		text-decoration: none;
		color: #000;
	}

	&:active{
		outline: none;
	}

	svg{
		transition: all 0.1s;
		fill: $color-text;
	}

	&--hollow{
		background: none;
		border-color: #DDDDDD;
		svg {
			fill: $color-text;
		}
	}
	&--hollow-white{
		background: none;
		color: #fff;
		border-color: #fff;

		&:hover{
			background: #fff;
			border-color: rgba(#fff,0);
			color: $color-text;
		}
		&:active{
			background: darken(#fff, 5%);
			color: $color-text;
		}
	}

	&--hollow-green{
		background: none;
		color: $color-green;
		border-color: $color-green;
	}

	&--green{
		background: $color-green;
		color: #fff;
		svg{ fill: #fff; }
		&:hover{
			background: lighten($color-green, 2%);
			border-color: rgba(#fff,0);
			color: #fff;
		}
		&:active{
			background: darken($color-green, 3%);
			color: #fff;
		}
	}

	&[disabled] {
		opacity: 0.5;
		cursor: not-allowed;
	}

	&--hover-hollow{
		&:hover{
			background: none;
			border-color: #DDDDDD;
			color: $color-text;
			svg{ fill: $color-text; }
		}
	}

	&--hover-green{
		&:hover{
			background: lighten($color-green, 2%);
			border-color: rgba(#fff,0);
			color: #fff;
		}
		&:active{
			background: darken($color-green, 3%);
			color: #fff;
		}
	}
}