.wrap{
	width: 100%;
	max-width: 1000px;
	padding-left: 25px;
	padding-right: 25px;
	margin-left: auto;
	margin-right: auto;

	@include media-breakpoint-up(md) {
		padding-left: 50px;
		padding-right: 50px;
	}

	@include media-breakpoint-up(lg) {
		padding-left: $gw / 2;
		padding-right: $gw / 2;
	}
}
