
.order-form {
	&__title{
		margin-bottom: 30px;
	}

	&__inner{
		max-width: 575px;
		margin: 0 auto;
	}

	&__group{
		margin-bottom: 10px;
	}
	&__input{
		display: block;
		width: 100%;
		border: solid rgba($color-brown, 0.3);
		border-width: 0 0 2px;
		padding: 0;
		height: 45px;
		&:focus{
			outline: none;
		}
		@at-root{
			textarea.order-form__input {
				padding: 14px 0;
				resize: vertical;
				max-height: 300px;
				min-height: 45px;
			}
		}

		@include placeholderStyle {
			font-size: 12px;
			color: #A9A9A9;
			text-transform: uppercase;
			font-family: $font-1;
		}
	}

	&__captcha{
		margin: 30px 0;
		img{
			max-width: 100%;
		}
	}
}
