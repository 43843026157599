.desktop-header {
	.sub-nav {
		a.active-nav {
			font-weight: bold;
		}
	}
}

.header-order-btn {
	@extend %link-clicker;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-align: center;

	height: 28px;
	padding: 0 19px;

	border-radius: 20px;
	border: none;

	background-color: $color-red;
	color: #fff;

	user-select: none;
	font-family: $font-1;
	font-weight: $fw-regular;
	cursor: pointer;
	text-decoration: none;
	font-size: 12px;
	text-transform: uppercase;

	box-shadow: none;
	transition: background 0.15s, color 0.15s, border 0.15s;
	&:hover{
		text-decoration: none;
		color: #fff;
		background-color: lighten($color-red, 3%);
	}

	&:active{
		outline: none;
		background-color: darken($color-red, 2%);
	}

	> svg {
		@include media-breakpoint-up(md) {
			display: none;
		}
	}

	> span {
		display: none;
		@include media-breakpoint-up(md) {
			display: block;
		}
	}

	&--pos-mob {
		margin: 6px 0 0 10px;
		@include media-breakpoint-down(sm) {
			padding: 0;
			width: 28px;
		}
	}

	&--pos-desktop {
		margin: 0 0 0 5px;
	}

	&--pos-menu {
		color: #fff !important;
		text-decoration: none !important;
		font-weight: $fw-regular !important;
	}
}
