.b-person {
	&__row {
		@include make-row(0);
	}

	&__col-content,
	&__col-img {
		@include make-col-ready(0);
		@include media-breakpoint-up(md) {
			height: 570px;
		}
	}

	&__col-img {
		@include media-breakpoint-up(md) {
			@include make-col(1,2)
		}
		@include media-breakpoint-up(lg) {
			@include col-grow();
		}

		&--order-md-1{
			@include media-breakpoint-up(md) {
				order: 1;
			}
		}
		&--visible-lg{
			display: none;
			@include media-breakpoint-up(lg) {
				display: block;
			}
		}
	}

	&__col-content {
		background-color: $color-brown;
		color: #fff;
		padding: 40px 25px;

		@include media-breakpoint-up(md) {
			@include make-col(1,2);
			padding: 110px 51px 40px;
		}

		@include media-breakpoint-up(lg) {
			@include col-auto();
			width: 440px;
		}
		@include media-breakpoint-up(xl) {
			@include make-col(2,5);
			width: auto;
		}
	}

	&__img-wrap{
		height: 570px;
		@include media-breakpoint-up(md) {
			height: 100%;
		}
		&:before{
			padding-bottom: 0;
		}
	}

	&__content{
		font-size: 13px;
		font-weight: $fw-light;
		line-height: em(24, 13);

		i ,
		em ,
		p {
			font-weight: inherit;
		}
	}

	&__title{
		font-size: 14px;
		font-weight: $fw-regular;
	}
}

.team-images {
	&--offset-top{
		margin-top: 60px;
	}
	&__list{
		display: flex;
		flex-wrap: wrap;

		list-style: none;
		padding: 0;
		margin-top: -32px;

		@include media-breakpoint-up(md) {
			margin-left: -20px;
			margin-right: -20px;
		}

		@include media-breakpoint-up(lg) {
			margin-left: -35px;
			margin-right: -35px;
			margin-top: -50px;
		}
	}
	&__item{
		@include make-col-ready(0);
		margin-top: 32px;

		@include media-breakpoint-up(md) {
			@include make-col(1,2);
			padding-left: 20px;
			padding-right: 20px;
		}

		@include media-breakpoint-up(lg) {
			padding-left: 35px;
			padding-right: 35px;
			margin-top: 50px;
		}
	}
}

.item-team-images {
	display: block;
	text-decoration: none;
	&:hover{
		text-decoration: none;
	}
	&__img-wrap{
		&:before{
			padding-bottom: cp(300, 450);
		}
	}
	&__overlay{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($color-green, 0.75);
		border-bottom: 0 solid $color-brown;

		opacity: 0;
		transition: all 0.2s;

		.item-team-images:hover &{
			opacity: 1;
			border-bottom-width: 9px;
		}
	}
	&__icon{
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: em(-38, 76);
		margin-left: em(-38, 76);

		font-size: 52px;
		width: 1em;
		height: 1em;

		border-radius: 50%;
		border: 2px solid $color-brown;
		opacity: 0;
		transition: all 0.2s;
		transform: scale(0.8);

		@include media-breakpoint-up(lg) {
			font-size: 76px;
		}

		.item-team-images:hover &{
			opacity: 1;
			transform: scale(1);
		}

		&:before,
		&:after{
			content: '';
			position: absolute;
			top: 0; bottom: 0; left: 0; right: 0;
			margin: auto;
			background-color: $color-brown;
		}
		&:before{
			width: em(28, 76);
			height: 2px;
		}
		&:after{
			width: 2px;
			height: em(28, 76);
		}
	}
	&__title{
		margin-top: 10px;
		font-size: 24px;
		color: $color-brown;
		.item-team-images:hover &{
			text-decoration: underline;
		}
	}
}