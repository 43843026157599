img{
	&[data-object-fit="contain"] {
		object-fit: contain;
	}
	&[data-object-fit="cover"] {
		object-fit: cover;
	}
	&[data-object-fit="scale-down"] {
		object-fit: scale-down;
	}
}

.fit-img{
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	>img{
		opacity: 0 !important;
	}
}

.visuallyhidden {
	position: absolute;
	overflow: hidden;
	clip: rect(0 0 0 0);
	height: 1px; width: 1px;
	margin: -1px; padding: 0; border: 0;
}

.responsive-img{
	position: relative;
	&:before{
		content: '';
		display: block;
		width: 100%;
		height: 0;
		padding-bottom: cp(1,1)
	}
	>img{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&--2x1:before{ padding-bottom: cp(1,2) }
	&--3x2:before{ padding-bottom: cp(2,3) }
	&--4x3:before{ padding-bottom: cp(3,4) }
	&--14x9:before{ padding-bottom: cp(9,14) }
	&--16x9:before{ padding-bottom: cp(9,16) }
}


.text{
	&-center{ text-align: center; }
	&-left{ text-align: left; }
	&-right{ text-align: right; }
}

.divider{
	&--mt-5{
		margin-top: 35px;
	}
	&--mb-0{
		margin-bottom: 0;
	}
	&--mb-5{
		margin-bottom: 35px;
	}
}

.g-section {
	padding: 45px 0;

	@include media-breakpoint-up(lg) {
		padding: 80px 0;
	}
	&--pt-3{
		padding-top: 1.5rem;
	}
}

.g-wide-block {
	@include media-breakpoint-up(lg) {
		width: 100%;
		max-width: 85%;
		min-width: 992px;
		margin-left: auto;
		margin-right: auto;
	}
}