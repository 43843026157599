.menu-list {
	&--offset-y {
		margin: 30px 0;
	}

	&__devider {
		margin: 20px 0 30px;
	}

	&__title {
		font-size: 30px;
		color: $color-brown;
	}

	&__slider {
		height: auto;
		width: auto;

		margin-left: -25px;
		margin-right: -25px;

		@include media-breakpoint-up(md) {
			margin: 0;
		}

		.slick {

			&-track,
			&-list,
			&-slide {
				height: auto;
			}

			&-list {
				margin: 0 -10px;
			}

			&-slide {
				margin: 0 10px;
			}

			&-dots {
				padding: 30px 0;
			}
		}
	}

	&__slide {
		display: none;
	}
}

.item-menu {
	display: block;

	&+& {
		margin-top: 10px;

		@include media-breakpoint-up(md) {
			margin-top: 25px;
		}
	}

	&__img-wrap {
		&:before {
			padding-bottom: cp(200, 300);
		}
	}

	&__overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($color-green, 0.75);
		border-bottom: 0 solid $color-brown;

		opacity: 0;
		transition: all 0.2s;

		.item-menu:hover & {
			opacity: 1;
			border-bottom-width: 9px;
		}
	}

	&__text {
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		padding: 0 40px;
		transform: translateY(-50%);
		margin: 0;
		text-align: center;

		text-transform: uppercase;
		color: $color-brown;
		font-size: 13px;
		font-weight: $fw-semibold;

		opacity: 0;
		transition: all 0.2s;

		.item-menu:hover & {
			opacity: 1;
		}
	}
}

.menu-files {
	padding-top: 0;

	&__title {
		font-size: 30px;
		color: $color-brown;
		font-weight: $fw-regular;
		margin: 0 10px;
	}

	&__devider {
		margin: 20px 0 40px;
	}

	&__list {
		@include make-row(40);
		list-style: none;
		padding: 0;
		margin-top: -40px;
	}

	&__item {
		@include make-col-ready(40);
		margin-top: 40px;

		@include media-breakpoint-up(lg) {
			@include make-col(1, 3);
		}
	}
}

.item-menu-files {
	display: flex;
	align-items: flex-start;

	text-decoration: none;
	&:hover{
		text-decoration: none;
		color: inherit;
	}

	&__icon {
		@include col-auto();
		display: block;
		width: 86px;
		margin-right: 10px;

		&:before {
			padding-bottom: cp(105, 86);
		}
	}

	&__content {
		@include col-grow();
	}

	&__info {
		font-size: 11px;
		margin-bottom: 5px;
		span{
			color: #888888;
		}
	}
	&__title {
		display: block;
		font-size: 20px;
		font-weight: $fw-regular;
		color: $color-brown;
		margin-bottom: 10px;

		.item-menu-files:hover &{
			text-decoration: underline;
		}
	}

	&__descr{
		line-height: 1.4;
		max-height: (3em * 1.4);
		overflow: hidden;
	}
}
