
.b-contacts {
	background-color: #F0F3F1;

	&__title{
		margin-bottom: 28px;
	}
	&__descr{
		max-width: 560px;
		margin: 0 auto 70px;
	}

	&__list{
		@include make-row();
		padding: 0;
		margin-top: -50px;
		list-style: none;
	}

	&__item{
		@include make-col-ready();
		margin-top: 50px;
		text-align: center;

		@include media-breakpoint-up(md) {
			@include col-grow();
			border-right: 1px solid rgba($color-brown, 0.2);
		}

		&:last-child{
			border: none;
		}
	}

	&__item-icon{
		display: block;
		margin-bottom: 40px;
		font-size: 26px;
		height: 1em;

		&--pin{
			svg {
				width: em(16, 26);
				height: em(25, 26);
			}
		}
		&--plane{
			svg {
				width: em(29, 26);
				height: em(24, 26);
			}
		}
		&--phone{
			svg {
				width: em(26, 26);
				height: em(26, 26);
			}
		}
	}

	&__item-title{
		margin-bottom: 24px;
		text-transform: uppercase;
		font-size: 13px;
	}

	&__item-text{
		font-size: 13px;
		font-weight: $fw-light;

		a {
			text-decoration: none;
		}
	}
}
