.modal-gallery {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	padding: 40px 0;

	&__close{
		position: absolute;
		top: 0;
		right: 0;
		width: 40px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 24px;
		font-family: sans-serif;
		color: #fff;

		background-color: rgba(#000, 0.5);

		cursor: pointer;
	}

	&__slider {
		display: none;
		height: 100%;

		&.slick-initialized{
			display: block;
		}

		button.slick-arrow {
			position: absolute;
			z-index: 1;
			top: 50%;
		}
		button.slick-prev {
			left: 0;
		}
		button.slick-next {
			right: 0;
		}
	}

	&__slide {}

	&__img-wrap {
		height: 100%;
		&:before{
			display: none;
		}

		img {
			opacity: 0;
		}
	}

	&__footer{
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 10px 0 10px;
		background-color: rgba(#000, 0.5);


		color: #fff;
	}

	&__footer-inner{
		display: flex;
		align-items: flex-end;
	}

	&__title{
		@include col-grow();
		width: 100%;
		min-height: 1px;
	}
	&__paging{
		@include col-auto();
		min-height: 1px;
	}
}